import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import mapCustomStyles from "./mapCustomStyles";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const mapStyles = {
      width: "100%",
      height: this.props.height,
    };
    return (
      <Map
        google={this.props.google}
        zoom={15}
        style={mapStyles}
        styles={this.props.mapStyle}
        initialCenter={{ lat: 9.761927, lng: 79.95244 }}
      >
        {this.props.positions.map((el, id) => {
          return <Marker key={`marker${id}`} position={{ lat: el.lat, lng: el.lng }} />;
        })}
      </Map>
    );
  }
}

MapContainer.defaultProps = mapCustomStyles;

export default GoogleApiWrapper({
  apiKey: "AIzaSyAEb9fogblI6_rvhZl8WxmA6OrlTxeH4r0",
})(MapContainer);
