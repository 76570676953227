import React from "react";
import { useDropzone } from "react-dropzone";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./index.less";

const Dropzone = ({ style, className, files, setFiles, type = "image" }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: `${type}/*`,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const onReset = () => {
    setFiles([]);
  };

  return (
    <div className="onyx-dropzone" style={style}>
      <div
        {...getRootProps({
          className: `dropzone ${className ? className : ""}`,
        })}
      >
        {files.length === 0 ? (
          <>
            <input {...getInputProps()} />
            <img src="/images/upload.png" alt="upload" />
            <p>Drag a file here or browse a file to upload</p>
          </>
        ) : (
          <>
            <img src={files[0].preview} alt="preview" />
            <CloseCircleOutlined onClick={onReset} className="text-white reset-icon" />
          </>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
