import React, { useState, useMemo } from "react";
import { Table } from "antd";
import moment from "moment";
import { Layout, AppBar, Card, Select, Label, GoogleMap } from "UI";
import useSWR from "swr";
import withLabel from "Helpers/withLabel";
import { fetcher } from "Utils/request";

import "./index.less";

const { Column } = Table;

const TrackList = ({ isAuthenticated }) => {
  const [sponsor, setSponsor] = useState();
  const [match, setMatch] = useState();
  const [date, setDate] = useState();
  const [location, setLocation] = useState();

  const { data: matchList } = useSWR("/matches/all", fetcher);
  const { data: sponsorList } = useSWR("/sponsors/all", fetcher);
  // const { data: dataList } = useSWR("/tracking/all", fetcher);

  // console.log(dataList);
  console.log("matchList>>>", matchList);
  const matchData = useMemo(
    () =>
      !matchList
        ? []
        : matchList.map((val) => ({ label: val.title, key: val.matchId, value: val.id })),
    [matchList]
  );

  // const matchTableData = useMemo(
  //   () =>
  //     !dataList
  //       ? []
  //       : dataList.map((val, index) => {
  //           if (val.Match && val.Sponsor) {
  //             return {
  //               ...val,
  //               key: index,
  //               title: val.Match.name,
  //               ip: val.ip,
  //               date: val.Match.createdAt,
  //               sponsor: val.Sponsor.name,
  //             };
  //           }
  //         }),
  //   [dataList]
  // );

  const sponsorData = useMemo(
    () =>
      !sponsorList ? [] : sponsorList.map((val) => ({ label: val.name, value: val.id })),
    [sponsorList]
  );

  const locationData = [
    { label: "United States", value: "United States" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "Canada", value: "Canada" },
    { label: "Australia", value: "Australia" },
  ];
  const dateData = [
    {
      label: "2020",
      value: 2020,
    },
  ];

  const { data: trackList } = useSWR("/tracking/all", fetcher);
  const trackData = useMemo(
    () =>
      !trackList
        ? []
        : trackList.map((val) => ({
            sponsor: val.Sponsor.name,
            title: val.Match.name,
            key: val.id,
            date: val.createdAt,
            ip: val.ip,
          })),
    [trackList]
  );

  const trackingStack = (
    <div className="onyx-breadcrumb">
      <span className="text-gray">Tracking</span>
    </div>
  );

  return (
    <Layout menuSelected={3}>
      <AppBar title="IP LIST" stackNavigator={trackingStack} />
      <div className="onyx-flex-layout">
        <div className="col-2-3">
          <div className="onyx-flex-layout">
            <div className="col-left">
              <Card className="p-1">
                {withLabel(
                  <Label>{(trackList || []).length}</Label>,
                  "Total Clicks",
                  true
                )}
              </Card>
              <div className="onyx-flex-layout">
                <div className="col-left">
                  <Select
                    placeholder="Sponsor"
                    showSearch={true}
                    value={sponsor}
                    onChange={(e) => console.log(e)}
                    options={sponsorData}
                  />
                </div>
                <div className="col-right">
                  <Select
                    placeholder="Match"
                    showSearch={true}
                    value={match}
                    onChange={(e) => console.log(e)}
                    options={matchData}
                  />
                </div>
              </div>
            </div>

            <div className="col-right">
              <Card className="p-1">
                {withLabel(
                  <Label>
                    {[...new Set((trackList || []).map((item) => item.ip))].length}
                  </Label>,
                  "Total Users",
                  true
                )}
              </Card>
              <div className="onyx-flex-layout">
                <div className="col-left">
                  <Select
                    placeholder="Date"
                    showSearch={true}
                    value={date}
                    onChange={(e) => console.log(e)}
                    options={dateData}
                  />
                </div>
                <div className="col-right">
                  <Select
                    placeholder="Location"
                    showSearch={true}
                    value={location}
                    onChange={(e) => console.log(e)}
                    options={locationData}
                  />
                </div>
              </div>
            </div>
          </div>

          <Card className="p-0 mt-4">
            <Table dataSource={trackData} pagination={true}>
              <Column title="SPONSOR" dataIndex="sponsor" key="sponsor" />
              <Column title="MATCH" dataIndex="title" key="title" />
              <Column
                title="DATE"
                dataIndex="date"
                key="date"
                sorter={(a, b) => moment(b.date).unix() - moment(a.date).unix()}
                defaultSortOrder="ascend"
                render={(date) => <div>{moment(date).format("MMMM Do, YYYY")}</div>}
              />
              <Column title="IP LOG" key="ip" dataIndex="ip" />
            </Table>
          </Card>
          <div className="onyx-action" onClick={() => console.log("Exporting CSV...")}>
            EXPORT CSV
          </div>
        </div>

        <div className="col-1-3">
          <Card className="p-0 mb-0">
            <div className="onyx-map-demo-portrait">
              <GoogleMap
                height={500}
                positions={[
                  {
                    lat: 9.761927,
                    lng: 79.95244,
                  },
                  {
                    lat: 9.762927,
                    lng: 79.95344,
                  },
                ]}
              />
            </div>
            {/* <img
              src="/images/map_demo_1.png"
              alt="map"
              className="onyx-map-demo-portrait"
            /> */}
          </Card>

          <Card title="New Egypt" className="p-1">
            <div className="onyx-flex-layout">
              <div className="col-left">
                {withLabel(<Label>{(trackList || []).length}</Label>, "CLICKS", true)}
              </div>
              <div className="col-right">
                {withLabel(
                  <Label>
                    {[...new Set((trackList || []).map((item) => item.ip))].length}
                  </Label>,
                  "USERS",
                  true
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default TrackList;
