import React, { useState } from "react";
import { Layout as Wrapper, Menu, Avatar, Dropdown } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import MenuItem from "../MenuItem";
import "./index.less";

const { Header, Content, Sider } = Wrapper;

export const LayoutComponent = ({ children, menuSelected }) => {
  const location = useLocation();
  const history = useHistory();
  const [collapsed, changeCollapse] = useState(false);

  // const onCollapse = (collapsed) => {
  //   changeCollapse(collapsed);
  // };

  const handleLogOut = () => {};

  const profileMenu = (
    <Menu>
      <Menu.Item key="0">
        <a href="/">Profile</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="/logout">Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrapper className="onyx-layout">
      <Sider width={300} className="onyx-sidebar">
        <div className="logo">
          <img src="/images/logo.png" alt="logo" />
        </div>
        <MenuItem isSelected={menuSelected === 0} onClick={() => history.push("/")}>
          Sponsor
        </MenuItem>
        <MenuItem
          isSelected={menuSelected === 1}
          onClick={() => history.push("/matches")}
        >
          Matches
        </MenuItem>
        <MenuItem isSelected={menuSelected === 2} onClick={() => history.push("/users")}>
          Users
        </MenuItem>
        <MenuItem
          isSelected={menuSelected === 3}
          onClick={() => history.push("/tracking")}
        >
          Tracking
        </MenuItem>
      </Sider>
      <Wrapper className="onyx-body">
        <Header className="onyx-header">
          <div className="onyx-header-text o-8 text-white">Onyx Admin</div>

          <Dropdown
            overlay={profileMenu}
            overlayClassName="onyx-profile-dropdown"
            trigger={["click"]}
          >
            <Avatar src="/images/avatar.png" />
          </Dropdown>
        </Header>
        <Content className="onyx-content">{children}</Content>
      </Wrapper>
    </Wrapper>
  );
};

export default LayoutComponent;
