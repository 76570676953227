import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import useSWR from "swr";
import SponsorList from "Component/Sponsor";
import SponsorEdit from "Component/SponsorEdit";
import MatchList from "Component/Matches";
import MatchesEdit from "Component/MatchesEdit";
import UserList from "Component/Users";
import UsersEdit from "Component/UsersEdit";
import TrackList from "Component/Tracking";
import Login from "Component/Login";
import Logout from "Component/Logout";
import { isTokenExpired, getUserId } from "Utils/persist";
import { fetcher } from "Utils/request";
import "antd/dist/antd.css";
import ScrollIntoView from "../Component/ScrollIntoView";

const PrivateRoute = ({ children, isAuthenticated, ...rest }) => {
  return !isAuthenticated ? (
    <Redirect to="/login" />
  ) : (
    <Route {...rest}>{children}</Route>
  );
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!isTokenExpired());
  const userId = getUserId();

  const [responseError, setResponseError] = useState(false);

  const swrOptions = {
    revalidateOnFocus: false,
    onError: (err, key, config) => {
      setResponseError({ err, key, config });
    },
    onSuccess: () => {
      setResponseError(false);
    },
  };

  const [loading, setLoading] = useState(false);
  const { data: sponsorList } = useSWR(
    isAuthenticated ? "/sponsors/all" : [],
    fetcher,
    swrOptions
  );

  const { data: userList } = useSWR(
    isAuthenticated ? "/users/all" : [],
    fetcher,
    swrOptions
  );

  if (loading || responseError) return <div>Loading...</div>;

  return (
    <Router>
      <ScrollIntoView>
        <Switch>
          <Route path="/login" exact>
            <Login onSuccess={() => setIsAuthenticated(true)} />
          </Route>
          <Route path="/logout" exact>
            <Logout onSuccess={() => setIsAuthenticated(false)} />
          </Route>
          <PrivateRoute path="/" isAuthenticated={isAuthenticated} exact>
            <SponsorList sponsorList={sponsorList} />
          </PrivateRoute>
          <PrivateRoute path="/sponsors" isAuthenticated={isAuthenticated} exact>
            <SponsorList sponsorList={sponsorList} />
          </PrivateRoute>
          <PrivateRoute path="/sponsors/:id" isAuthenticated={isAuthenticated} exact>
            <SponsorEdit sponsorList={sponsorList} />
          </PrivateRoute>
          <PrivateRoute path="/matches" isAuthenticated={isAuthenticated} exact>
            <MatchList />
          </PrivateRoute>
          <PrivateRoute path="/matches/:id" isAuthenticated={isAuthenticated} exact>
            <MatchesEdit />
          </PrivateRoute>
          <PrivateRoute path="/users" isAuthenticated={isAuthenticated} exact>
            <UserList userList={userList} />
          </PrivateRoute>
          <PrivateRoute path="/users/:id" isAuthenticated={isAuthenticated} exact>
            <UsersEdit />
          </PrivateRoute>
          <PrivateRoute path="/tracking" isAuthenticated={isAuthenticated} exact>
            <TrackList />
          </PrivateRoute>
        </Switch>
      </ScrollIntoView>
    </Router>
  );
}

export default App;
